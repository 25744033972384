/*
===============================================================================
    File        =   sign-in-app/src/reducers/auth.js
    Client      =   Rhealize
    Project     =   Ikimy Platform
    Purpose     =   This file contains the reducer for the auth slice which
                    will be used to update the auth state in the store
                    
===============================================================================
    Revision History
        -----------------------------------------------------------------------
        Version Date        Author              Comments
        -----------------------------------------------------------------------
        1.0     2024.06.18  Moataz Khallaf    Initial Creation
        -----------------------------------------------------------------------
===============================================================================
*/
import {
	GET_AUTH_TOKEN_ERROR,
	GET_AUTH_TOKEN_REQUEST,
	GET_AUTH_TOKEN_SUCCESS,
	VALIDATE_AUTH_TOKEN_ERROR,
	VALIDATE_AUTH_TOKEN_REQUEST,
	VALIDATE_AUTH_TOKEN_SUCCESS,
	SIGN_OUT_REQUEST,
	SIGN_OUT_SUCCESS,
	SIGN_OUT_ERROR,
} from "../actionTypes/auth";

let initialState = {
	// This will store app data as it pertains to authorization
	// Bearer token, user privileges, enabled features, etc

	authToken: "",
	authTokenLoading: false,
	authTokenError: null,

	validateAuthTokenLoading: false,
	validateAuthTokenError: null,

	signOutLoading: false,
	signOutError: null,
};

function reducer(state = initialState, action) {
	switch (action.type) {
		case GET_AUTH_TOKEN_REQUEST:
			return {
				...state,
				authTokenLoading: true,
				authTokenError: null,
			};

		case GET_AUTH_TOKEN_SUCCESS:
			return {
				...state,
				authTokenLoading: false,
				authToken: action.payload.authToken,
			};

		case GET_AUTH_TOKEN_ERROR:
			return {
				...state,
				authTokenLoading: false,
				authTokenError: action.payload,
			};

		case VALIDATE_AUTH_TOKEN_REQUEST:
			return {
				...state,
				validateAuthTokenLoading: true,
				validateAuthTokenError: null,
			};

		case VALIDATE_AUTH_TOKEN_SUCCESS:
			return {
				...state,
				validateAuthTokenLoading: false,
			};

		case VALIDATE_AUTH_TOKEN_ERROR:
			return {
				...state,
				validateAuthTokenLoading: false,
				validateAuthTokenError: action.payload,
			};

		case SIGN_OUT_REQUEST:
			return {
				...state,
				signOutLoading: true,
				signOutError: null,
			};

		case SIGN_OUT_SUCCESS:
			return {
				...state,
				signOutLoading: false,
				authToken: "",
			};

		case SIGN_OUT_ERROR:
			return {
				...state,
				signOutLoading: false,
				signOutError: action.payload,
			};

		default:
			return state;
	}
}

export default reducer;
