/*
===============================================================================
    File        =   sign-in-app/src/api/config.js
    Client      =   Rhealize
    Project     =   Ikimy Platform
    Purpose     =   This file contains the configuration for the API calls
                    
===============================================================================
    Revision History
        -----------------------------------------------------------------------
        Version Date        Author              Comments
        -----------------------------------------------------------------------
        1.0     2024.06.18  Moataz Khallaf    Initial Creation
        -----------------------------------------------------------------------
===============================================================================
*/
export const apiURL = process.env.REACT_APP_BACKEND_RECRUITER_SERVICE;
export const ENV = process.env.REACT_APP_ENV;
export const AUTH_PROXY = process.env.REACT_APP_AUTH_PROXY;
