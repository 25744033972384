/*
===============================================================================
    File        =   sign-in-app/src/utils/loader/Loader.js
    Client      =   Rhealize
    Project     =   Ikimy Platform
    Purpose     =   This file contains the loader component which is used to
                    display a loading spinner while the application is fetching
                    data or performing an operation
                    
===============================================================================
    Revision History
        -----------------------------------------------------------------------
        Version Date        Author              Comments
        -----------------------------------------------------------------------
        1.0     2024.06.18  Moataz Khallaf    Initial Creation
        -----------------------------------------------------------------------
===============================================================================
*/
import React from "react";
import "./Loader.css";
import { Loader as AmplifyLoader } from "@aws-amplify/ui-react";

const Loader = ({
	width = "10rem",
	height = "10rem",
	transform = "-50%, -50%",
}) => {
	const containerStyle = {
		transform: `translate(${transform})`,
	};
	return (
		<div className="loader-overlay">
			<div className="loader-container" style={containerStyle}>
				<AmplifyLoader width={width} height={height}></AmplifyLoader>
			</div>
		</div>
	);
};

export default Loader;
