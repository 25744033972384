/*
===============================================================================
    File        =   sign-in-app/src/reducers/root.js
    Client      =   Rhealize
    Project     =   Ikimy Platform
    Purpose     =   This file contains the root reducer for the application which
                    combines all the reducers into one
                    
===============================================================================
    Revision History
        -----------------------------------------------------------------------
        Version Date        Author              Comments
        -----------------------------------------------------------------------
        1.0     2024.06.18  Moataz Khallaf    Initial Creation
        -----------------------------------------------------------------------
===============================================================================
*/ import { combineReducers } from "redux";
import auth from "./auth";
import account from "./account";
import twoFactorAuth from "./twoFactorAuth";

export default combineReducers({
	auth,
	account,
	twoFactorAuth,
});
