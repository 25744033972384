/*
===============================================================================
    File        =   sign-in-app/src/api/account.js
    Client      =   Rhealize
    Project     =   Ikimy Platform
    Purpose     =   This file
                    
===============================================================================
    Revision History
        -----------------------------------------------------------------------
        Version Date        Author              Comments
        -----------------------------------------------------------------------
        1.0     2024.07.31  Moataz Khallaf    	Initial Creation
		1.1		2024.09.03	Daniel Rashidian	Forward to frontend server for 
												mfa response
        -----------------------------------------------------------------------
===============================================================================
*/

import {
	signUpRequest as signUpRequestAction,
	signUpSuccess,
	signUpAwaitingVerification,
	signUpError,
	confirmSignUpRequest as confirmSignUpRequestAction,
	confirmSignUpSuccess,
	confirmSignUpError,
	signInRequest as signInRequestAction,
	signInSuccess,
	signInError,
	answerLoginChallengeRequest as answerLoginChallengeRequestAction,
	answerLoginChallengeSuccess,
	answerLoginChallengeError,
} from "../actions/account";
import { apiURL } from "./config";

export function signUpRequest(dispatch, signUpRequestModel) {
	dispatch(signUpRequestAction());
	console.log(apiURL);
	fetch(`${apiURL}/auth/company-sign-up`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(signUpRequestModel),
	}).then(
		(response) => {
			if (!response.ok) {
				return response
					.json()
					.then((err) => {
						if (
							response.status === 409 &&
							err.detail &&
							err.detail ===
								`User with this email ${signUpRequestModel.email} is awaiting verification.`
						) {
							dispatch(signUpAwaitingVerification(err));
						} else {
							const errMessage =
								err.detail || "An error occured during signup";
							dispatch(
								signUpError({
									status: response.status,
									message: errMessage,
								})
							);
						}
					})
					.catch(() => {
						dispatch(
							signUpError({
								status: response.status,
								message: "An error occured during signup",
							})
						);
					});
			} else {
				return response.json().then((data) => {
					dispatch(signUpSuccess(data));
				});
			}
		},

		(error) => {
			console.error("Problem with sign up operation", error);
			dispatch(
				signUpError({
					status: "Network Error",
					message:
						error.message || "Network error occurred during signup",
				})
			);
		}
	);
}

export function confirmSignUpRequest(dispatch, confirmSignUpRequestModel) {
	console.log(confirmSignUpRequestModel);
	dispatch(confirmSignUpRequestAction());
	fetch(`${apiURL}/auth/confirm-sign-up`, {
		method: "POST",
		body: JSON.stringify(confirmSignUpRequestModel),
		headers: {
			"Content-Type": "application/json",
		},
	}).then(
		(response) => {
			if (!response.ok) {
				return response
					.json()
					.then((err) => {
						const errMessage =
							err.detail ||
							"An error occured during confirm signup";
						dispatch(
							confirmSignUpError({
								status: response.status,
								message: errMessage,
							})
						);
					})
					.catch(() => {
						dispatch(
							confirmSignUpError({
								status: response.status,
								message:
									"An error occured during confirm signup",
							})
						);
					});
			} else {
				return response.json().then((data) => {
					dispatch(confirmSignUpSuccess(data));
				});
			}
		},

		(error) => {
			console.error("Problem with confirm sign up operation", error);
			dispatch(
				confirmSignUpError({
					status: "Network Error",
					message:
						error.message || "Network error occurred during signup",
				})
			);
		}
	);
}

export function signInRequest(dispatch, signInRequestModel) {
	dispatch(signInRequestAction());
	const env = process.env.REACT_APP_ENV;
	let url;
	if (env && env === "local") {
		url = `${apiURL}/auth/login`;
	} else {
		url = "/auth/login";
	}
	fetch(url, {
		method: "POST",
		body: JSON.stringify(signInRequestModel),
		headers: {
			"Content-Type": "application/json",
		},
	}).then(
		(response) => {
			if (!response.ok) {
				return response
					.json()
					.then((err) => {
						const errMessage =
							err.detail || "An error occured during sign in";
						dispatch(
							signInError({
								status: response.status,
								message: errMessage,
							})
						);
					})
					.catch(() => {
						dispatch(
							signInError({
								status: response.status,
								message: "An error occured during sign in",
							})
						);
					});
			} else {
				return response.json().then((data) => {
					dispatch(signInSuccess(data));
				});
			}
		},

		(error) => {
			console.error("Problem with sign in operation", error);
			dispatch(
				signInError({
					status: "Network Error",
					message:
						error.message ||
						"Network error occurred during sign in",
				})
			);
		}
	);
}

export function answerLoginChallengeRequest(
	dispatch,
	answerLoginChallengeRequestModel
) {
	dispatch(answerLoginChallengeRequestAction());
	const env = process.env.REACT_APP_ENV;
	let url;
	if (env && env === "local") {
		url = `${apiURL}/auth/mfa`;
	} else {
		url = "/auth/mfa";
	}
	fetch(url, {
		method: "POST",
		body: JSON.stringify(answerLoginChallengeRequestModel),
		headers: {
			"Content-Type": "application/json",
		},
	}).then(
		(response) => {
			if (!response.ok) {
				return response
					.json()
					.then((err) => {
						const errMessage =
							err.detail ||
							"An error occured during login challenge";
						dispatch(
							answerLoginChallengeError({
								status: response.status,
								message: errMessage,
							})
						);
					})
					.catch(() => {
						dispatch(
							answerLoginChallengeError({
								status: response.status,
								message:
									"An error occured during login challenge",
							})
						);
					});
			} else {
				return response.json().then((data) => {
					dispatch(answerLoginChallengeSuccess(data));
				});
			}
		},

		(error) => {
			console.error("Problem with login challenge operation", error);
			dispatch(
				answerLoginChallengeError({
					status: "Network Error",
					message:
						error.message ||
						"Network error occurred during login challenge",
				})
			);
		}
	);
}
