/*
===============================================================================
    File        =   sign-in-app/src/actions/twoFactorAuth.js
    Client      =   Rhealize
    Project     =   Ikimy Platform
    Purpose     =   This file contains 2FA actions that can be dispatched
                    
===============================================================================
    Revision History
        -----------------------------------------------------------------------
        Version Date        Author              Comments
        -----------------------------------------------------------------------
        1.0     2024.08.06  Moataz Khallaf    Initial Creation
        -----------------------------------------------------------------------
===============================================================================
*/
import {
	TWO_FACTOR_COMPLETE_SETUP_ERROR,
	TWO_FACTOR_COMPLETE_SETUP_REQUEST,
	TWO_FACTOR_COMPLETE_SETUP_SUCCESS,
	TWO_FACTOR_KEY_SETUP_ERROR,
	TWO_FACTOR_KEY_SETUP_REQUEST,
	TWO_FACTOR_KEY_SETUP_SUCCESS,
} from "../actionTypes/twoFactorAuth";

export function twoFactorCompleteSetupRequest() {
	return {
		type: TWO_FACTOR_COMPLETE_SETUP_REQUEST,
	};
}

export function twoFactorCompleteSetupSuccess(data) {
	return {
		type: TWO_FACTOR_COMPLETE_SETUP_SUCCESS,
		payload: data,
	};
}

export function twoFactorCompleteSetupError(error) {
	return {
		type: TWO_FACTOR_COMPLETE_SETUP_ERROR,
		payload: error,
	};
}

export function twoFactorKeySetupRequest() {
	return {
		type: TWO_FACTOR_KEY_SETUP_REQUEST,
	};
}

export function twoFactorKeySetupSuccess(data) {
	return {
		type: TWO_FACTOR_KEY_SETUP_SUCCESS,
		payload: data,
	};
}

export function twoFactorKeySetupError(error) {
	return {
		type: TWO_FACTOR_KEY_SETUP_ERROR,
		payload: error,
	};
}
