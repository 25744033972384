/*
===============================================================================
    File        =   sign-in-app/src/app/store.js
    Client      =   Rhealize
    Project     =   Ikimy Platform
    Purpose     =   This file is used to create the Redux store for the React App
                    
===============================================================================
    Revision History
        -----------------------------------------------------------------------
        Version Date        Author              Comments
        -----------------------------------------------------------------------
        1.0     2024.06.17  Moataz Khallaf    Initial Creation
        -----------------------------------------------------------------------
===============================================================================
*/
import { configureStore } from "@reduxjs/toolkit";
import root from "../reducers/root";

export default configureStore({
	reducer: root,
	// middleware: [thunkMiddleWare] is not needed unless you have additional middleware
});
