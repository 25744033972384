/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import AppLogo from "./AppLogo";
import {
  Button,
  Flex,
  PasswordField,
  Text,
  TextField,
} from "@aws-amplify/ui-react";
export default function SignInForm(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="24px"
      direction="column"
      width="720px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="32px 48px 32px 48px"
      {...getOverrideProps(overrides, "SignInForm")}
      {...rest}
    >
      <Flex
        gap="24px"
        direction="column"
        width="unset"
        height="767px"
        justifyContent="flex-start"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 2px 0px 2px"
        {...getOverrideProps(overrides, "SignUpFormSigninDefault")}
      >
        <Flex
          gap="24px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="24px 0px 24px 0px"
          {...getOverrideProps(overrides, "Frame 915")}
        >
          <AppLogo
            display="flex"
            gap="4.253963470458984px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="6.806341648101807px 6.806341648101807px 6.806341648101807px 6.806341648101807px"
            darkMode="False"
            {...getOverrideProps(overrides, "AppLogo")}
          ></AppLogo>
        </Flex>
        <Text
          fontFamily="Inter"
          fontSize="32px"
          fontWeight="600"
          color="rgba(0,0,0,1)"
          lineHeight="40px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Sign in"
          {...getOverrideProps(overrides, "Sign in")}
        ></Text>
        <TextField
          width="unset"
          height="unset"
          label="Company Code *"
          placeholder="Enter company code"
          shrink="0"
          alignSelf="stretch"
          size="default"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          {...getOverrideProps(overrides, "Email585734772")}
        ></TextField>
        <TextField
          width="unset"
          height="unset"
          label="User E-mail *"
          placeholder="Type your e-mail"
          shrink="0"
          alignSelf="stretch"
          size="default"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          {...getOverrideProps(overrides, "Email585734771")}
        ></TextField>
        <Flex
          gap="0"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Password")}
        >
          <Flex
            gap="32px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "NameGroup")}
          >
            <PasswordField
              width="unset"
              height="unset"
              label="Password  *"
              placeholder="Type your password"
              grow="1"
              shrink="1"
              basis="0"
              size="default"
              isDisabled={false}
              labelHidden={false}
              variation="default"
              hideShowPassword={false}
              {...getOverrideProps(overrides, "PasswordField")}
            ></PasswordField>
          </Flex>
        </Flex>
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="400"
          color="rgba(45,63,92,1)"
          lineHeight="24px"
          textAlign="right"
          display="block"
          direction="column"
          justifyContent="unset"
          textDecoration="underline"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Forgot password?"
          {...getOverrideProps(overrides, "Forgot password?")}
        ></Text>
        <Button
          width="unset"
          height="unset"
          borderRadius="4px"
          shrink="0"
          alignSelf="stretch"
          backgroundColor="rgba(49,91,155,1)"
          size="default"
          isDisabled={false}
          variation="default"
          children="Sign In"
          {...getOverrideProps(overrides, "Button")}
        ></Button>
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="400"
          color="rgba(25,25,25,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Not registered? Create an account."
          {...getOverrideProps(overrides, "Not registered? Create an account.")}
        ></Text>
      </Flex>
    </Flex>
  );
}
