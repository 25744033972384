/*
===============================================================================
    File        =   sign-in-app/src/api/twoFactorAuth.js
    Client      =   Rhealize
    Project     =   Ikimy Platform
    Purpose     =   This file contains the API calls for two-factor authentication
                    
===============================================================================
    Revision History
        -----------------------------------------------------------------------
        Version Date        Author              Comments
        -----------------------------------------------------------------------
        1.0     2024.07.31  Moataz Khallaf    	Initial Creation
		1.1		2024.09.04	Daniel Rashidian	Import apiURL from config
        -----------------------------------------------------------------------
===============================================================================
*/

import {
	twoFactorKeySetupRequest,
	twoFactorKeySetupSuccess,
	twoFactorKeySetupError,
	twoFactorCompleteSetupError,
	twoFactorCompleteSetupRequest,
	twoFactorCompleteSetupSuccess,
} from "../actions/twoFactorAuth";
import { apiURL } from "./config";

export function setupMFARequest(dispatch, twoFactorSetupRequestModel) {
	dispatch(twoFactorKeySetupRequest());
	fetch(`${apiURL}/auth/mfa-setup`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(twoFactorSetupRequestModel),
	}).then(
		(response) => {
			if (!response.ok) {
				return response
					.json()
					.then((err) => {
						dispatch(
							twoFactorKeySetupError({
								status: response.status,
								message: err.detail,
							})
						);
					})
					.catch(() => {
						dispatch(
							twoFactorKeySetupError({
								status: response.status,
								message: "An error occured during MFA setup",
							})
						);
					});
			} else {
				return response.json().then((data) => {
					dispatch(twoFactorKeySetupSuccess(data));
				});
			}
		},

		(error) => {
			console.error("Problem with MFA setup operation", error);
			dispatch(
				twoFactorKeySetupError({
					status: "Network Error",
					message:
						error.message ||
						"Network error occurred during MFA setup",
				})
			);
		}
	);
}

export function completeSetupMFARequest(
	dispatch,
	completeTwoFactorAuthRequestModel
) {
	dispatch(twoFactorCompleteSetupRequest());
	fetch(`${apiURL}/auth/mfa-setup-complete`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(completeTwoFactorAuthRequestModel),
	}).then(
		(response) => {
			if (!response.ok) {
				return response
					.json()
					.then((err) => {
						dispatch(
							twoFactorCompleteSetupError({
								status: response.status,
								message: err.detail,
							})
						);
					})
					.catch(() => {
						dispatch(
							twoFactorCompleteSetupError({
								status: response.status,
								message:
									"An error occured during MFA verification",
							})
						);
					});
			} else {
				return response.json().then((data) => {
					dispatch(twoFactorCompleteSetupSuccess(data));
				});
			}
		},

		(error) => {
			console.error("Problem with confirm sign up operation", error);
			dispatch(
				twoFactorCompleteSetupError({
					status: "Network Error",
					message:
						error.message || "Network error occurred during signup",
				})
			);
		}
	);
}
