import { SignUpSuccess } from "../../../ui-components";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetState } from "../../../actions/account";

function Success({ email, password, firstName }) {
	const navigate = useNavigate();

	const dispatch = useDispatch();

	const navigateToSignIn = useCallback(() => {
		dispatch(resetState());
		navigate("/signin", { state: { email: email, password: password } });
	}, [navigate, dispatch, email, password]);

	const signUpSuccessOverrides = useMemo(() => {
		return {
			SignUpSuccess: {
				height: "100vh",
				width: "50vw",
				overflow: "auto",
				padding: "5px 48px 24px 48px",
			},

			"Welcome Timothy": {
				children: `Welcome ${firstName}`,
			},

			"Just one final step remains to complete your sign-up process. Kindly check your email inbox for a unique company code. We've successfully sent an email containing the code to the address timothy.o.ojo@gmail.com. This code will be needed when you proceed to the sign-in page.":
				{
					children: `Just one final step remains to complete your sign-up process. Kindly check your email inbox for a unique company code. We've successfully sent an email containing the code to the address ${email}. This code will be needed when you proceed to the sign-in page.`,
				},

			Button585734759: {
				onClick: navigateToSignIn,
				children: "Sign In",
			},

			email: email,
			password: password,
		};
	}, [navigateToSignIn, email, password, firstName]);

	return <SignUpSuccess overrides={signUpSuccessOverrides}></SignUpSuccess>;
}

export default Success;
