/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import AppLogo from "./AppLogo";
import {
  Button,
  CheckboxField,
  Flex,
  PasswordField,
  PhoneNumberField,
  Text,
  TextField,
} from "@aws-amplify/ui-react";
export default function SignUpFormSignupDefault(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="24px"
      direction="column"
      width="720px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="32px 48px 32px 48px"
      {...getOverrideProps(overrides, "SignUpFormSignupDefault")}
      {...rest}
    >
      <Flex
        gap="24px"
        direction="column"
        width="unset"
        height="97px"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="24px 0px 24px 0px"
        {...getOverrideProps(overrides, "Frame 914")}
      >
        <AppLogo
          display="flex"
          gap="4.253963470458984px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="6.806341648101807px 6.806341648101807px 6.806341648101807px 6.806341648101807px"
          darkMode="False"
          {...getOverrideProps(overrides, "AppLogo")}
        ></AppLogo>
      </Flex>
      <Text
        fontFamily="Inter"
        fontSize="32px"
        fontWeight="600"
        color="rgba(0,0,0,1)"
        lineHeight="40px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Sign up"
        {...getOverrideProps(overrides, "Sign up")}
      ></Text>
      <Flex
        gap="24px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 1104")}
      >
        <TextField
          width="unset"
          height="unset"
          label="Company Name *"
          placeholder="Type Company Name "
          grow="1"
          shrink="1"
          basis="0"
          size="default"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          {...getOverrideProps(overrides, "FirstName585734707")}
        ></TextField>
      </Flex>
      <Text
        fontFamily="Inter"
        fontSize="20px"
        fontWeight="600"
        color="rgba(45,63,92,1)"
        lineHeight="30px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="30px"
        gap="unset"
        alignItems="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Administrator User Details"
        {...getOverrideProps(overrides, "label")}
      ></Text>
      <Flex
        gap="32px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "NameGroup")}
      >
        <TextField
          width="unset"
          height="unset"
          label="First Name *"
          placeholder="Type your first name"
          grow="1"
          shrink="1"
          basis="0"
          size="default"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          {...getOverrideProps(overrides, "FirstName585734710")}
        ></TextField>
        <TextField
          width="unset"
          height="unset"
          label="Last Name *"
          placeholder="Type your last name"
          grow="1"
          shrink="1"
          basis="0"
          size="default"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          {...getOverrideProps(overrides, "LastName")}
        ></TextField>
      </Flex>
      <TextField
        width="unset"
        height="unset"
        label="E-mail *"
        placeholder="Type your e-mail"
        shrink="0"
        alignSelf="stretch"
        size="default"
        isDisabled={false}
        labelHidden={false}
        variation="default"
        {...getOverrideProps(overrides, "Email")}
      ></TextField>
      <PhoneNumberField
        width="unset"
        height="unset"
        label="Phone *"
        placeholder="Type your phone number"
        shrink="0"
        alignSelf="stretch"
        size="default"
        isDisabled={false}
        labelHidden={false}
        variation="default"
        {...getOverrideProps(overrides, "PhoneNumberField")}
      ></PhoneNumberField>
      <Flex
        gap="32px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "PasswordGroup")}
      >
        <PasswordField
          width="unset"
          height="unset"
          label="Password  *"
          placeholder="Type your password"
          grow="1"
          shrink="1"
          basis="0"
          size="default"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          hideShowPassword={false}
          {...getOverrideProps(overrides, "PasswordField")}
        ></PasswordField>
        <PasswordField
          width="unset"
          height="unset"
          label="Confirm Password  *"
          placeholder="Confirm your password"
          grow="1"
          shrink="1"
          basis="0"
          size="default"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          hideShowPassword={false}
          {...getOverrideProps(overrides, "ConfirmPasswordField")}
        ></PasswordField>
      </Flex>
      <Flex
        gap="10px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "TermsOfService")}
      >
        <CheckboxField
          width="unset"
          height="unset"
          label="Agree with Terms of service and Privacy Policy"
          justifyContent="flex-start"
          shrink="0"
          alignSelf="stretch"
          size="default"
          defaultChecked={false}
          isDisabled={false}
          labelPosition="end"
          {...getOverrideProps(overrides, "CheckboxField")}
        ></CheckboxField>
      </Flex>
      <Flex
        gap="24px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 98")}
      >
        <Button
          width="unset"
          height="unset"
          border="1px SOLID rgba(103,82,30,1)"
          grow="1"
          shrink="1"
          basis="0"
          size="default"
          isDisabled={false}
          variation="primary"
          children="Sign Up"
          {...getOverrideProps(overrides, "Button")}
        ></Button>
      </Flex>
      <Text
        fontFamily="Inter"
        fontSize="16px"
        fontWeight="400"
        color="rgba(25,25,25,1)"
        lineHeight="24px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Already have an account? Login"
        {...getOverrideProps(overrides, "Already have an account? Login")}
      ></Text>
    </Flex>
  );
}
