/*
===============================================================================
    File        =   sign-in-app/src/actions/fetchData.js
    Client      =   Rhealize
    Project     =   Ikimy Platform
    Purpose     =   This file contains requests to fetch data from API
                    
===============================================================================
    Revision History
        -----------------------------------------------------------------------
        Version Date        Author              Comments
        -----------------------------------------------------------------------
        1.0     2024.08.28  Crush Bate         Initial Creation
        -----------------------------------------------------------------------
===============================================================================
*/
import {  resendVerificationRequest as resendVerificationRequestAction, resendVerificationError, resendVerificationSuccess, forgotPasswordRequest as forgotPasswordRequestAction, forgotPasswordError, forgotPasswordSuccess, resetPasswordRequest as resetPasswordRequestAction, resetPasswordError, resetPasswordSuccess} from "./action";

const apiURL = process.env.REACT_APP_BACKEND_CANDIDATE_SERVICE

export function resendVerificationRequest(dispatch, resendVerificationRequestModel) {
    dispatch(resendVerificationRequestAction());
    fetch(`${apiURL}/auth/resendverification`, {
        method: "PUT",
        body: JSON.stringify(resendVerificationRequestModel),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        if (!response.ok) {
            return response.json().then(err => {
                const errMessage = err.detail || 'An error occured during resend verification'
                dispatch(resendVerificationError({"status": response.status, "message": errMessage}));
            }).catch(() => {
                dispatch(resendVerificationError({"status": response.status, "message": 'An error occured during resend verification'}));
            });
        } else {
            return response.json().then(data => {
                dispatch(resendVerificationSuccess(data));
            })
        }
    },

    (error) => {
        console.error("Problem with resend verification operation", error);
        dispatch(resendVerificationError({"status": "Network Error", "message": error.message || 'Network error occurred during resend verification'}));
    });
}

export function forgotPasswordRequest(dispatch, forgotPasswordRequestModel) {
    dispatch(forgotPasswordRequestAction());
    fetch(`${apiURL}/auth/forgotpassword`, {
        method: "POST",
        body: JSON.stringify(forgotPasswordRequestModel),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        if (!response.ok) {
            return response.json().then(err => {
                const errMessage = err.detail || 'An error occured during forgot password'
                dispatch(forgotPasswordError({"status": response.status, "message": errMessage}));
            }).catch(() => {
                dispatch(forgotPasswordError({"status": response.status, "message": 'An error occured during forgot password'}));
            });
        } else {
            return response.json().then(data => {
                dispatch(forgotPasswordSuccess(data));
            })
        }
    },

    (error) => {
        console.error("Problem with forgot password operation", error);
        dispatch(forgotPasswordError({"status": "Network Error", "message": error.message || 'Network error occurred during forgot password'}));
    });
}

export function resetPasswordRequest(dispatch, resetPasswordRequestModel) {
    dispatch(resetPasswordRequestAction());
    fetch(`${apiURL}/auth/resetpassword`, {
        method: "PUT",
        body: JSON.stringify(resetPasswordRequestModel),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        if (!response.ok) {
            return response.json().then(err => {
                const errMessage = err.detail || 'An error occured during reset password'
                dispatch(resetPasswordError({"status": response.status, "message": errMessage}));
            }).catch(() => {
                dispatch(resetPasswordError({"status": response.status, "message": 'An error occured during reset password'}));
            });
        } else {
            return response.json().then(data => {
                dispatch(resetPasswordSuccess(data));
            })
        }
    },

    (error) => {
        console.error("Problem with reset password operation", error);
        dispatch(resetPasswordError({"status": "Network Error", "message": error.message || 'Network error occurred during reset password'}));
    });
}
