/*
===============================================================================
    File        =   sign-in-app/src/index.js
    Client      =   Rhealize
    Project     =   Ikimy Platform
    Purpose     =   This file is the entry point for the React App and is used to
                    render the App component to the root element in the index.html
                    file
                    
===============================================================================
    Revision History
        -----------------------------------------------------------------------
        Version Date        Author              Comments
        -----------------------------------------------------------------------
        1.0     2024.06.17  Moataz Khallaf    Initial Creation
        -----------------------------------------------------------------------
===============================================================================
*/
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app/App';
import reportWebVitals from './reportWebVitals';

import { ThemeProvider } from "@aws-amplify/ui-react";

import store from './app/store';

import {Provider} from 'react-redux'

//import awsconfig from './aws-exports';

//import "@aws-amplify/ui-react/styles.css";


import { studioTheme } from "./ui-components";

//Amplify.configure(awsconfig);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <ThemeProvider theme={studioTheme}>
        <App />
      </ThemeProvider>
    </React.StrictMode>
  </Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
