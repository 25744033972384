/*
===============================================================================
    File        =   sign-in-app/src/app/App.js
    Client      =   Rhealize
    Project     =   Ikimy Platform
    Purpose     =   This file is the react app
                    
===============================================================================
    Revision History
        -----------------------------------------------------------------------
        Version Date        Author              Comments
        -----------------------------------------------------------------------
        1.0     2024.06.17  Moataz Khallaf    Initial Creation
		1.1     2024.08.27  Crush Bate        Added route to forgotpassword
        -----------------------------------------------------------------------
===============================================================================
*/
import "./App.css";
import ProtectedRoute from "../auth/ProtectedRoute";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	useNavigate,
	Navigate,
	useLocation,
} from "react-router-dom";
import SignIn from "../pages/Account/SignIn/SignIn";
import SignUp from "../pages/Account/SignUp/SignUp";
import ForgotPassword from "../pages/Account/ForgotPassword/ForgotPassword";

function AppWrapper() {
	return (
		<Router>
			<App />
		</Router>
	);
}

function App() {
	return (
		<Routes>
			<Route path="/" element={<Navigate to="/signin" replace />} />
			<Route path="/signin" element={<SignIn />} />
			<Route path="/signup" element={<SignUp />} />
			<Route path="/forgotpassword" element={<ForgotPassword/>} />
		</Routes>
	);
}

export default AppWrapper;
