/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import {
  Button,
  Flex,
  PasswordField,
  Text,
  TextField,
} from "@aws-amplify/ui-react";
export default function ResetPasswordMain(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="24px"
      direction="column"
      width="762px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      borderRadius="4px"
      padding="64px 60px 64px 60px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "ResetPasswordMain")}
      {...rest}
    >
      <Flex
        width="unset"
        height="unset"
        {...getOverrideProps(overrides, "AppLogo")}
      ></Flex>
      <Text
        fontFamily="Inter"
        fontSize="32px"
        fontWeight="700"
        color="rgba(48,64,80,1)"
        lineHeight="48px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Reset Password"
        {...getOverrideProps(overrides, "Reset Password")}
      ></Text>
      <Flex
        gap="16px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 1160464084")}
      >
        <TextField
          width="unset"
          height="unset"
          label="Verification Code"
          placeholder="Enter Code"
          shrink="0"
          alignSelf="stretch"
          size="default"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          {...getOverrideProps(overrides, "TextField")}
        ></TextField>
        <Flex
          gap="23px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 102")}
        >
          <PasswordField
            width="unset"
            height="72px"
            label="New Password *"
            placeholder=""
            grow="1"
            shrink="1"
            basis="0"
            size="default"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            hideShowPassword={false}
            {...getOverrideProps(overrides, "PasswordField694911510")}
          ></PasswordField>
        </Flex>
        <Flex
          gap="23px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 103")}
        >
          <PasswordField
            width="unset"
            height="72px"
            label="Retype New Password *"
            placeholder=""
            grow="1"
            shrink="1"
            basis="0"
            size="default"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            hideShowPassword={false}
            {...getOverrideProps(overrides, "PasswordField694911512")}
          ></PasswordField>
        </Flex>
      </Flex>
      <Flex
        gap="24px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 1160464409")}
      >
        <Button
          width="unset"
          height="unset"
          grow="1"
          shrink="1"
          basis="0"
          size="default"
          isDisabled={false}
          variation="primary"
          children="Reset Password"
          {...getOverrideProps(overrides, "Button694911514")}
        ></Button>
        <Button
          width="319.5px"
          height="42px"
          border="1px SOLID rgba(49,91,155,1)"
          shrink="0"
          size="default"
          isDisabled={false}
          variation="default"
          children="Resend Code"
          {...getOverrideProps(overrides, "Button694911515")}
        ></Button>
      </Flex>
    </Flex>
  );
}
