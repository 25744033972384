/*
===============================================================================
    File        =   sign-in-app/src/pages/Account/Forgotpassword/ResetPassword.js
    Client      =   Rhealize
    Project     =   Ikimy Platform
    Purpose     =   This file contains the rest password page UI
                    
===============================================================================
    Revision History
        -----------------------------------------------------------------------
        Version Date        Author              Comments
        -----------------------------------------------------------------------
        1.0     2024.08.27  Crush Bate         Initial Creation
        -----------------------------------------------------------------------
===============================================================================
*/
import { ResetPasswordMain } from '../../../ui-components';
import { AppLogo } from '../../../ui-components';
import {useState, useEffect, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { resetPassword as resetPasswordForUser } from '../../../actions/reducers/reducers';
import Loader from '../../../utils/loader/Loader';


const selectResetPasswordLoading = (state) => {return state.account.resetPasswordLoading};
const selectResetPasswordError = (state) => {return state.account.resetPasswordError};
// const selectForgotPasswordStatus = (state) => {return state.account.forgotPasswordStatus};


function ResetPassword({ email, onForgotPassword }) {
    const resetPasswordLoading = useSelector((state) => selectResetPasswordLoading(state));
    const resetPasswordError = useSelector((state) => selectResetPasswordError(state));
    // const forgotPasswordError = useSelector((state) => selectForgotPasswordError(state));
    // const forgotPasswordStatus = useSelector((state) => selectForgotPasswordStatus(state));

    const [resetPasswordMainOverrides, setResetPasswordMainOverrides] = useState({});
    const [code, setCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const [codeError, setCodeError] = useState('');
    const [newPasswordError, setNewPasswordError] = useState('');
    const [confirmNewPasswordError,  setConfirmNewPasswordError] = useState('');

    const dispatch = useDispatch();

    const handleCodeChange = (event) => {
        setCode(event.target.value);
    };

    const handleNewPasswordChange = (event) => {
        setNewPassword(event.target.value);
    };

    const handleConfirmNewPasswordChange = (event) => {
        setConfirmNewPassword(event.target.value);
    };

    const validateCode = useCallback(() => {
        if (code.trim() === '') {
            setCodeError('Code is required.')
        }

        const VALID_CODE_PATTERN = /^\d{6}$/;
        if (!VALID_CODE_PATTERN.test(code)) {
            setCodeError('Six digit code required.')
            return false;
        }
        setCodeError('');
        return true;
    }, [code]);

    const validateNewPassword = useCallback(() => {
        if (newPassword === '') {
            setNewPasswordError('Password is required.')
            return false;
        }

        if (newPassword.trim() !== newPassword) {
            setNewPasswordError('Password cannot begin or end with a space.')
            return false;
        }

        if (newPassword.length < 10) {
            setNewPasswordError("Password must be at least 10 characters long")
            return false;
        }
        if (!/[0-9]/.test(newPassword)) {
            setNewPasswordError("Password must include at least one number");
            return false;
        }
  
        if (!/[A-Z]/.test(newPassword)) {
            setNewPasswordError("Password must include at least one uppercase letter");
            return false;
        }
        if (!/[a-z]/.test(newPassword)) {
            setNewPasswordError("Password must include at least one lowercase letter");
            return false;
        }

        const VALID_PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\^\$\*\.\[\]\{\}\(\)\?\"\!@#%&\/\\,><\':;|_~\`+= -])[A-Za-z\d\^\$\*\.\[\]\{\}\(\)\?\"\!@#%&\/\\,><\':;|_~\`+= -]{10,}$/;
        if (!VALID_PASSWORD_REGEX.test(newPassword)) {
            setNewPasswordError("Password must include at least one special symbol");
            return false;
        }

        setNewPasswordError('');
        return true;
    }, [newPassword]);

    const validateConfirmNewPassword = useCallback(() => {
        if (confirmNewPassword === '') {
            setConfirmNewPasswordError('Please confirm your new password.');
            return false;
        }
        if (newPassword !== confirmNewPassword) {
            setConfirmNewPasswordError('Passwords do not match.');
            return false;
        }
        setConfirmNewPasswordError('');
        return true;
    }, [confirmNewPassword, newPassword]);


    useEffect(() => {
        if (resetPasswordError) {
            if (resetPasswordError.status === 401) {
                setCodeError("This code is not valid. Please try again.")
            }

            else if (resetPasswordError.status === 410) {
                setCodeError("This code is expired. Please request a new verification code to be sent.")
            }   

            else if (resetPasswordError.status === 429) {
                setCodeError("Too many incorrect attempts. Please try again later.")
            }   
        }
    }, [resetPasswordError]);

    const handleResetPassword = useCallback(async (event) => {
        event.preventDefault();

        const validations = [
            validateCode,
            validateNewPassword,
            validateConfirmNewPassword
        ];

        const allValid = validations.map(validation => validation()).every(result => result)

        if (allValid) {
            const resetPasswordRequestBody = {
                email: email,
                new_password: newPassword,
                confirm_new_password: confirmNewPassword,
                code: code
            };

            resetPasswordForUser(dispatch, resetPasswordRequestBody)
        }

    }, [validateCode, validateNewPassword, validateConfirmNewPassword, dispatch, email, code, newPassword, confirmNewPassword]);

    useEffect(() => {
        const newResetPasswordMainOverrides = {
            ...resetPasswordMainOverrides,
            AppLogo: {
                children: <AppLogo />,
            },
            "TextField": {
                value: code,
                onChange: handleCodeChange,
                hasError: codeError ? true : false,
                errorMessage: codeError
            },

            "PasswordField694911510": {
                value: newPassword,
                onChange: handleNewPasswordChange,
                hasError: newPasswordError ? true : false,
                errorMessage: newPasswordError
            },

            "PasswordField694911512": {
                value: confirmNewPassword,
                onChange: handleConfirmNewPasswordChange,
                hasError: confirmNewPasswordError ? true : false,
                errorMessage: confirmNewPasswordError
            },
            
            "Button694911514": {
                onClick: handleResetPassword

            },

            "Button694911515": {
                onClick: onForgotPassword
            }
        };

        if (JSON.stringify(newResetPasswordMainOverrides) !== JSON.stringify(resetPasswordMainOverrides)) {
            setResetPasswordMainOverrides(newResetPasswordMainOverrides);
        }
    }, [resetPasswordMainOverrides, code, codeError, newPassword, newPasswordError, confirmNewPassword, confirmNewPasswordError, handleResetPassword, onForgotPassword]);

    return (
        <>
            {resetPasswordLoading && <Loader width="10rem" height="10rem" transform="-50%, -100%"/>}
            {  
             <ResetPasswordMain overrides={resetPasswordMainOverrides}/>
            }
        </> 
    )
};

export default ResetPassword;