/*
===============================================================================
    File        =   sign-in-app/src/actionTypes/twoFactorAuth.js
    Client      =   Rhealize
    Project     =   Ikimy Platform
    Purpose     =   This file contains the action types for the twoFactorAuth slice which
                    will be used to define the actions for said slice
                    
===============================================================================
    Revision History
        -----------------------------------------------------------------------
        Version Date        Author              Comments
        -----------------------------------------------------------------------
        1.0     2024.07.04  Moataz Khallaf    Initial Creation
        -----------------------------------------------------------------------
===============================================================================
*/

export const TWO_FACTOR_KEY_SETUP_REQUEST = "TWO_FACTOR_KEY_SETUP_REQUEST";
export const TWO_FACTOR_KEY_SETUP_SUCCESS = "TWO_FACTOR_KEY_SETUP_SUCCESS";
export const TWO_FACTOR_KEY_SETUP_ERROR = "TWO_FACTOR_KEY_SETUP_ERROR";

export const TWO_FACTOR_COMPLETE_SETUP_REQUEST =
	"TWO_FACTOR_COMPLETE_SETUP_REQUEST";
export const TWO_FACTOR_COMPLETE_SETUP_SUCCESS = "TWO_FACTOR_SETUP_SUCCESS";
export const TWO_FACTOR_COMPLETE_SETUP_ERROR =
	"TWO_FACTOR_COMPLETE_SETUP_ERROR";
