/*
===============================================================================
    File        =   sign-in-app/src/reducers/twoFactorAuth.js
    Client      =   Rhealize
    Project     =   Ikimy Platform
    Purpose     =   This file contains the reducer for the  twoFactorAuth slice which
                    will be used to update the twoFactorAuth state in the store
                    
===============================================================================
    Revision History
        -----------------------------------------------------------------------
        Version Date        Author              Comments
        -----------------------------------------------------------------------
        1.0     2024.07.04  Moataz Khallaf    Initial Creation
        -----------------------------------------------------------------------
===============================================================================
*/
import {
	TWO_FACTOR_KEY_SETUP_REQUEST,
	TWO_FACTOR_KEY_SETUP_SUCCESS,
	TWO_FACTOR_KEY_SETUP_ERROR,
	TWO_FACTOR_COMPLETE_SETUP_ERROR,
	TWO_FACTOR_COMPLETE_SETUP_REQUEST,
	TWO_FACTOR_COMPLETE_SETUP_SUCCESS,
} from "../actionTypes/twoFactorAuth";

import { setupMFARequest, completeSetupMFARequest } from "../api/twoFactorAuth";

let initialState = {
	// This will store app data as it pertains to two factor authentication
	// private key, setup status, auth status, etc

	twoFactorPrivateKey: "",

	twoFactorKeySetupLoading: false,
	twoFactorKeySetupError: null,

	twoFactorKeyCompleteSetupLoading: false,
	twoFactorKeyCompleteSetupError: null,
};

function reducer(state = initialState, action) {
	switch (action.type) {
		case TWO_FACTOR_KEY_SETUP_REQUEST:
			return {
				...state,
				twoFactorKeySetupLoading: true,
				twoFactorKeySetupError: null,
			};

		case TWO_FACTOR_KEY_SETUP_SUCCESS:
			return {
				...state,
				twoFactorKeySetupLoading: false,
				twoFactorPrivateKey: action.payload.secret_code,
			};

		case TWO_FACTOR_KEY_SETUP_ERROR:
			return {
				...state,
				twoFactorKeySetupLoading: false,
				twoFactorKeySetupError: action.payload,
			};

		case TWO_FACTOR_COMPLETE_SETUP_REQUEST:
			return {
				...state,
				twoFactorKeyCompleteSetupLoading: true,
				twoFactorKeyCompleteSetupError: null,
			};

		case TWO_FACTOR_COMPLETE_SETUP_SUCCESS:
			return {
				...state,
				twoFactorKeyCompleteSetupLoading: false,
			};

		case TWO_FACTOR_COMPLETE_SETUP_ERROR:
			return {
				...state,
				twoFactorKeyCompleteSetupLoading: false,
				twoFactorKeyCompleteSetupError: action.payload,
			};

		default:
			return state;
	}
}

export function setupMFA(dispatch, twoFactorSetupRequestModel) {
	setupMFARequest(dispatch, twoFactorSetupRequestModel);
}

export function completeSetupMFA(dispatch, completeTwoFactorAuthRequestModel) {
	completeSetupMFARequest(dispatch, completeTwoFactorAuthRequestModel);
}

export default reducer;
