/*
===============================================================================
    File        =   sign-in-app/src/pages/Account/SignIn/TwoFactorQR.js
    Client      =   Rhealize
    Project     =   Ikimy Platform
    Purpose     =   TwoFactorQR Component, generates the QR code to autherize
					2FA for the user
                    
===============================================================================
    Revision History
        -----------------------------------------------------------------------
        Version Date        Author              Comments
        -----------------------------------------------------------------------
        1.0     2024.07.03  Moataz Khallaf    Initial Creation
        -----------------------------------------------------------------------
===============================================================================
*/
import QRCode from "react-qr-code";
import { Text, Button } from "@aws-amplify/ui-react";

const TwoFactorQR = ({ email, qrCode, setEnableAuth, setSignInState }) => {
	return (
		<div
			style={{
				height: "auto",
				margin: "0 auto",
				backgroundColor: "white",
			}}
		>
			<Text
				fontFamily="Inter"
				fontSize="24px"
				fontWeight="600"
				color="rgba(0,0,0,1)"
				lineHeight="40px"
				textAlign="center"
				display="block"
				direction="column"
				justifyContent="unset"
				width="unset"
				height="unset"
				gap="unset"
				alignItems="unset"
				shrink="0"
				alignSelf="stretch"
				position="relative"
				padding="12px"
				whiteSpace="pre-wrap"
			>
				Scan the QR code with your authenticator app
			</Text>
			<div
				style={{
					height: "auto",
					margin: "0 auto",
					padding: "12px",
					maxWidth: 256,
					width: "100%",
				}}
			>
				<QRCode
					size={256}
					style={{ height: "auto", maxWidth: "100%", width: "100%" }}
					value={`otpauth://totp/${email}?secret=${qrCode}&issuer=Rhealize`}
					viewBox={`0 0 256 256`}
				/>
				<Button
					style={{
						justifyContent: "center",
						alignItems: "center",
						display: "flex",
						marginTop: "12px",
					}}
					onClick={() => {
						setEnableAuth(false);
						setSignInState("authCodeInput");
					}}
				/>
			</div>
		</div>
	);
};

export default TwoFactorQR;
