/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Button, Flex, Icon, Text } from "@aws-amplify/ui-react";
export default function SignUpSuccess(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="22px"
      direction="column"
      width="624px"
      height="540px"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "SignUpSuccess")}
      {...rest}
    >
      <Flex
        gap="22px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 1160464445")}
      >
        <Flex
          padding="0px 0px 0px 0px"
          width="117px"
          height="117px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          {...getOverrideProps(overrides, "Group 911")}
        >
          <Icon
            width="65.36px"
            height="48.34px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 65.35867309570312,
              height: 48.33538055419922,
            }}
            paths={[
              {
                d: "M64.3871 0.919387C65.6619 2.16534 65.6852 4.2088 64.4393 5.48358L22.5566 48.3354L0.789076 23.2307C-0.378681 21.884 -0.233555 19.8455 1.11323 18.6778C2.46 17.51 4.49844 17.6551 5.6662 19.0019L22.8408 38.8094L59.8229 0.97159C61.0688 -0.303194 63.1123 -0.326566 64.3871 0.919387Z",
                fill: "rgba(119,202,133,1)",
                fillRule: "evenodd",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="33.89px"
            left="25.82px"
            {...getOverrideProps(overrides, "Vector 4 (Stroke)")}
          ></Icon>
          <Icon
            width="117px"
            height="117px"
            viewBox={{ minX: 0, minY: 0, width: 117, height: 117 }}
            paths={[
              {
                d: "M58.5 110.545C87.2436 110.545 110.545 87.2436 110.545 58.5C110.545 29.7564 87.2436 6.45517 58.5 6.45517C29.7564 6.45517 6.45517 29.7564 6.45517 58.5C6.45517 87.2436 29.7564 110.545 58.5 110.545ZM58.5 117C90.8087 117 117 90.8087 117 58.5C117 26.1913 90.8087 0 58.5 0C26.1913 0 0 26.1913 0 58.5C0 90.8087 26.1913 117 58.5 117Z",
                fill: "rgba(119,202,133,1)",
                fillRule: "evenodd",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0px"
            left="0px"
            {...getOverrideProps(overrides, "Ellipse 1 (Stroke)")}
          ></Icon>
        </Flex>
        <Text
          fontFamily="Inter"
          fontSize="32px"
          fontWeight="500"
          color="rgba(119,202,133,1)"
          lineHeight="40px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="568.6px"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Signup Successful!"
          {...getOverrideProps(overrides, "Signup Successful!")}
        ></Text>
      </Flex>
      <Flex
        gap="23px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 914")}
      >
        <Text
          fontFamily="Inter"
          fontSize="20px"
          fontWeight="500"
          color="rgba(34,59,85,1)"
          lineHeight="30px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="644.54px"
          height="26px"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Welcome Timothy"
          {...getOverrideProps(overrides, "Welcome Timothy")}
        ></Text>
        <Flex
          gap="36px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Email confirmation text")}
        >
          <Text
            fontFamily="Inter"
            fontSize="20px"
            fontWeight="400"
            color="rgba(92,102,112,1)"
            lineHeight="34px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="590px"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Just one final step remains to complete your sign-up process. Kindly check your email inbox for a unique company code. We've successfully sent an email containing the code to the address timothy.o.ojo@gmail.com. This code will be needed when you proceed to the sign-in page."
            {...getOverrideProps(
              overrides,
              "Just one final step remains to complete your sign-up process. Kindly check your email inbox for a unique company code. We've successfully sent an email containing the code to the address timothy.o.ojo@gmail.com. This code will be needed when you proceed to the sign-in page."
            )}
          ></Text>
          <Flex
            gap="32px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 913")}
          >
            <Button
              width="unset"
              height="unset"
              border="1px SOLID rgba(166,133,48,1)"
              grow="1"
              shrink="1"
              basis="0"
              backgroundColor="rgba(49,91,155,1)"
              size="default"
              isDisabled={false}
              variation="default"
              children="Login"
              {...getOverrideProps(overrides, "Button585734759")}
            ></Button>
            <Button
              width="unset"
              height="unset"
              border="1px SOLID rgba(49,91,155,1)"
              grow="1"
              shrink="1"
              basis="0"
              size="default"
              isDisabled={false}
              variation="default"
              children="Resend Email"
              {...getOverrideProps(overrides, "Button585734760")}
            ></Button>
          </Flex>
          <Flex
            padding="0px 0px 0px 0px"
            width="397px"
            height="34px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            {...getOverrideProps(overrides, "Group 912")}
          >
            <Text
              fontFamily="Inter"
              fontSize="20px"
              fontWeight="400"
              color="rgba(97,116,135,1)"
              lineHeight="34px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              position="absolute"
              top="0%"
              bottom="0%"
              left="0%"
              right="41.31%"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Do you have a problem ?"
              {...getOverrideProps(overrides, "Do you have a problem ?")}
            ></Text>
            <Text
              fontFamily="Inter"
              fontSize="20px"
              fontWeight="400"
              color="rgba(35,67,117,1)"
              lineHeight="30px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              textDecoration="underline"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              position="absolute"
              top="1.47%"
              bottom="10.29%"
              left="60.45%"
              right="0%"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Contact Support"
              {...getOverrideProps(overrides, "Contact Support")}
            ></Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
