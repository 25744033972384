/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import Check from "./Check";
import { Flex, Text } from "@aws-amplify/ui-react";
export default function LoginBullet(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="25px"
      direction="row"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      position="relative"
      border="1px SOLID rgba(255,255,255,0.35)"
      padding="15px 26px 15px 26px"
      {...getOverrideProps(overrides, "LoginBullet")}
      {...rest}
    >
      <Check
        width="32px"
        height="32px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Check")}
      ></Check>
      <Text
        fontFamily="Inter"
        fontSize="20px"
        fontWeight="400"
        color="rgba(255,255,255,1)"
        lineHeight="34px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Intuitive and effective"
        {...getOverrideProps(overrides, "Intuitive and effective")}
      ></Text>
    </Flex>
  );
}
