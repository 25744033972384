/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import AppLogo from "./AppLogo";
import { Button, Flex, Text, TextField } from "@aws-amplify/ui-react";
export default function AuthCodeInput(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="24px"
      direction="column"
      width="720px"
      height="831px"
      justifyContent="flex-end"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="32px 32px 32px 32px"
      {...getOverrideProps(overrides, "AuthCodeInput")}
      {...rest}
    >
      <Flex
        gap="24px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="24px 0px 24px 0px"
        {...getOverrideProps(overrides, "Frame 915")}
      >
        <AppLogo
          display="flex"
          gap="4.253963470458984px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="6.806341648101807px 6.806341648101807px 6.806341648101807px 6.806341648101807px"
          darkMode="False"
          {...getOverrideProps(overrides, "AppLogo")}
        ></AppLogo>
      </Flex>
      <Flex
        gap="24px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        overflow="hidden"
        grow="1"
        shrink="1"
        basis="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 4px 0px 4px"
        {...getOverrideProps(overrides, "SignUpFormSigninDefault")}
      >
        <Text
          fontFamily="Inter"
          fontSize="24px"
          fontWeight="600"
          color="rgba(0,0,0,1)"
          lineHeight="40px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Enter the code sent to your email address"
          {...getOverrideProps(
            overrides,
            "Enter the code sent to your email address"
          )}
        ></Text>
        <TextField
          width="unset"
          height="unset"
          placeholder="2345363564"
          shrink="0"
          alignSelf="stretch"
          size="default"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          {...getOverrideProps(overrides, "TextField")}
        ></TextField>
        <Button
          width="unset"
          height="unset"
          borderRadius="4px"
          shrink="0"
          alignSelf="stretch"
          backgroundColor="rgba(49,91,155,1)"
          size="large"
          isDisabled={false}
          variation="default"
          children="Next"
          {...getOverrideProps(overrides, "Button")}
        ></Button>
      </Flex>
    </Flex>
  );
}
