/*
===============================================================================
    File        =   sign-in-app/src/selectors/twoFactorAuth.js
    Client      =   Rhealize
    Project     =   Ikimy Platform
    Purpose     =   This file contains the selectors for the twoFactorAuth slice
                    
===============================================================================
    Revision History
        -----------------------------------------------------------------------
        Version Date        Author              Comments
        -----------------------------------------------------------------------
        1.0     2024.08.06  Moataz Khallaf    Initial Creation
        -----------------------------------------------------------------------
===============================================================================
*/

export const selectTwoFactorPrivateKey = (state) => {
	return state.twoFactorAuth.twoFactorPrivateKey;
};

export const selectTwoFactorKeySetupLoading = (state) => {
	return state.twoFactorAuth.twoFactorKeySetupLoading;
};
export const selectTwoFactorKeySetupError = (state) => {
	return state.twoFactorAuth.twoFactorKeySetupError;
};

export const selectTwoFactorKeyCompleteSetupLoading = (state) => {
	return state.twoFactorAuth.twoFactorKeyCompleteSetupLoading;
};
export const selectTwoFactorKeyCompleteSetupError = (state) => {
	return state.twoFactorAuth.twoFactorKeyCompleteSetupError;
};
