/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex, Text } from "@aws-amplify/ui-react";
import LoginBullet from "./LoginBullet";
export default function SignupLeftPanel(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="33px"
      direction="column"
      width="720px"
      height="896px"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="101px 48px 101px 48px"
      backgroundColor="rgba(34,66,114,1)"
      {...getOverrideProps(overrides, "SignupLeftPanel")}
      {...rest}
    >
      <Text
        fontFamily="Inter"
        fontSize="36px"
        fontWeight="700"
        color="rgba(254,254,254,1)"
        lineHeight="60px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Start Hiring with Ikimy"
        {...getOverrideProps(overrides, "Start Hiring with Ikimy")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="16px"
        fontWeight="400"
        color="rgba(254,254,254,1)"
        lineHeight="27.200000762939453px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Ikimy helps define what candidates want to contribute through their work and what the employer envisions the role to achieve. Discover where your job and candidate needs match."
        {...getOverrideProps(
          overrides,
          "Ikimy helps define what candidates want to contribute through their work and what the employer envisions the role to achieve. Discover where your job and candidate needs match."
        )}
      ></Text>
      <Flex
        gap="0"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 1073")}
      >
        <LoginBullet
          display="flex"
          gap="25px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          border="1px SOLID rgba(255,255,255,0.35)"
          padding="15px 0px 15px 0px"
          {...getOverrideProps(overrides, "LoginBullet")}
        ></LoginBullet>
        <LoginBullet
          display="flex"
          gap="25px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          border="1px SOLID rgba(255,255,255,0.35)"
          padding="15px 0px 15px 0px"
          {...getOverrideProps(overrides, "Frame 1069")}
        ></LoginBullet>
        <LoginBullet
          display="flex"
          gap="25px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          border="1px SOLID rgba(255,255,255,0.35)"
          padding="15px 0px 15px 0px"
          {...getOverrideProps(overrides, "Frame 1070")}
        ></LoginBullet>
        <LoginBullet
          display="flex"
          gap="25px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          border="1px SOLID rgba(255,255,255,0.35)"
          padding="15px 0px 15px 0px"
          {...getOverrideProps(overrides, "Frame 1071")}
        ></LoginBullet>
        <LoginBullet
          display="flex"
          gap="25px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          border="1px SOLID rgba(255,255,255,0.35)"
          padding="15px 0px 15px 0px"
          {...getOverrideProps(overrides, "Frame 1072")}
        ></LoginBullet>
      </Flex>
    </Flex>
  );
}
