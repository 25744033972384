/*
===============================================================================
    File        =   sign-in-app/src/actions/reducers/reducers.js
    Client      =   Rhealize
    Project     =   Ikimy Platform
    Purpose     =   This file contains reducers
                    
===============================================================================
    Revision History
        -----------------------------------------------------------------------
        Version Date        Author              Comments
        -----------------------------------------------------------------------
        1.0     2024.08.28  Crush Bate         Initial Creation
        -----------------------------------------------------------------------
===============================================================================
*/
import { RESEND_VERIFICATION_REQUEST, RESEND_VERIFICATION_SUCCESS, RESEND_VERIFICATION_ERROR, FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_ERROR, RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_ERROR,  RESET_STATE} from "../../actionTypes/auth";
import {forgotPasswordRequest, resendVerificationRequest, resetPasswordRequest} from "../fetchData";

let initialState = {
    signUpLoading: false,
    signUpError: null,
    signUpStatus: "" ,
    
    confirmSignUpLoading: false,
    confirmSignUpError: null,
    
    resendVerificationLoading: false,
    resendVerificationError: null,

    forgotPasswordLoading: false,
    forgotPasswordError: null,
    changePasswordStatus: "",

    resetPasswordLoading: false,
    resetPasswordError: null,

    signInLoading: false,
    signInError: null,
    token: "",
    tokenExpirationTime: null

};

function reducer(state = initialState, action) {
    switch (action.type) {
        case RESEND_VERIFICATION_REQUEST:
            return {
                ...state,
                resendVerificationLoading: true,
                resendVerificationError: null,
            };
    
        case RESEND_VERIFICATION_SUCCESS:
            return {
                ...state,
                resendVerificationLoading: false,
            };
    
        case RESEND_VERIFICATION_ERROR:
            return {
                ...state,
                confirmSignUpLoading: false,
                confirmSignUpError: action.payload
            };
            
        case FORGOT_PASSWORD_REQUEST:
            return {
                ...state,
                forgotPasswordLoading: true,
                forgotPasswordError: null,
                resetPasswordError: null,
            };
            
        case FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                forgotPasswordLoading: false,
                changePasswordStatus: "Awaiting Verification"
            };
            
        case FORGOT_PASSWORD_ERROR:
            return {
                ...state,
                forgotPasswordLoading: false,
                forgotPasswordError: action.payload
            };
            
        case RESET_PASSWORD_REQUEST:
            return {
                ...state,
                resetPasswordLoading: true,
                resetPasswordError: null
            };
                
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resetPasswordLoading: false,
                changePasswordStatus: "Success"
            };
                
        case RESET_PASSWORD_ERROR:
            return {
                ...state,
                resetPasswordLoading: false,
                resetPasswordError: action.payload
            };
 
        case RESET_STATE:
            return {
                ...state,
                resendVerificationLoading: false,
                resendVerificationError: null,
            
                forgotPasswordLoading: false,
                forgotPasswordError: null,
                changePasswordStatus: "",
            
                resetPasswordLoading: false,
                resetPasswordError: null,
            
            };
        
        default:
            return state;
    }
}

export function resendVerification(dispatch, resendVerificationRequestModel) {
    resendVerificationRequest(dispatch, resendVerificationRequestModel);
}

export function forgotPassword(dispatch, forgotPasswordRequestModel) {
    forgotPasswordRequest(dispatch, forgotPasswordRequestModel);
}

export function resetPassword(dispatch, resetPasswordRequestModel) {
    resetPasswordRequest(dispatch, resetPasswordRequestModel);
}

export default reducer;