/*
===============================================================================
    File        =   sign-in-app/src/pages/Account/SignUp/SignUp.js
    Client      =   Rhealize
    Project     =   Ikimy Platform
    Purpose     =   Sign Up Component
	
===============================================================================
    Revision History
        -----------------------------------------------------------------------
        Version Date        Author              Comments
        -----------------------------------------------------------------------
        1.0     2024.06.19  Moataz Khallaf    Initial Creation
		1.1     2024.06.20  Moataz Khallaf    integrate backend calls
        -----------------------------------------------------------------------
===============================================================================
*/
import "../account.css";
import { AppLogo } from "../../../ui-components";
import { SignUpFormSignupDefault } from "../../../ui-components";
import { useState } from "react";
import { useEffect, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { signUp as signUpUser } from "../../../reducers/account";
import Verification from "./Verification";
import Success from "./Success";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { resetState } from "../../../actions/account";
import Loader from "../../../utils/loader/Loader";
import StartHiring from "../StartHiring/StartHiring";
import { PrivacyPolicyTermsUseCombined, Close } from "../../../ui-components";
import { Button, Flex, Text } from "@aws-amplify/ui-react";

const selectSignUpLoading = (state) => {
	return state.account.signUpLoading;
};
const selectSignUpError = (state) => {
	return state.account.signUpError;
};
const selectSignUpStatus = (state) => {
	return state.account.signUpStatus;
};

function SignUp() {
	const signUpLoading = useSelector((state) => selectSignUpLoading(state));
	const signUpError = useSelector((state) => selectSignUpError(state));
	const signUpStatus = useSelector((state) => selectSignUpStatus(state));

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [company, setCompany] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [dialCode, setDialCode] = useState("+1");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [agreeTermsAndPolicy, setAgreeTermsAndPolicy] = useState(false);

	const [companyError, setCompanyError] = useState("");
	const [firstNameError, setFirstNameError] = useState("");
	const [lastNameError, setLastNameError] = useState("");
	const [emailError, setEmailError] = useState("");
	const [phoneError, setPhoneError] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const [confirmPasswordError, setConfirmPasswordError] = useState("");
	const [agreeTermsAndPolicyError, setAgreeTermsAndPolicyError] =
		useState("");

	const [tosAndPrivatePolicyVisible, setTosAndPrivatePolicyVisible] =
		useState(false);

	const location = useLocation();

	useEffect(() => {
		if (location.state?.email) {
			setEmail(location.state.email);
		}
	}, [location]);

	const handleCompanyChange = (event) => {
		setCompany(event.target.value);
	};
	const handleFirstNameChange = (event) => {
		setFirstName(event.target.value);
	};
	const handleLastNameChange = (event) => {
		setLastName(event.target.value);
	};
	const handleEmailChange = (event) => {
		setEmail(event.target.value);
	};
	const handlePhoneChange = (event) => {
		setPhone(event.target.value);
	};
	const handlePasswordChange = (event) => {
		setPassword(event.target.value);
	};
	const handleConfirmPasswordChange = (event) => {
		setConfirmPassword(event.target.value);
	};
	const handleAgreeTermsAndPolicy = (event) => {
		setAgreeTermsAndPolicy(event.target.checked);
	};
	const handleDialCodeChange = (event) => {
		setDialCode(event.target.value);
	};

	const validateCompany = useCallback(() => {
		if (company.trim() === "") {
			setCompanyError("Company name is required.");
			return false;
		}
		setCompanyError("");
		return true;
	}, [company]);

	const validateFirstName = useCallback(() => {
		if (firstName.trim() === "") {
			setFirstNameError("First name is required.");
			return false;
		}
		setFirstNameError("");
		return true;
	}, [firstName]);

	const validateLastName = useCallback(() => {
		if (lastName.trim() === "") {
			setLastNameError("Last name is required.");
			return false;
		}
		setLastNameError("");
		return true;
	}, [lastName]);

	const validateEmail = useCallback(() => {
		if (email.trim() === "") {
			setEmailError("Email address is required.");
			return false;
		}
		setEmailError("");
		return true;
	}, [email]);

	useEffect(() => {
		if (signUpError) {
			console.log(signUpError);
			if (
				signUpError.status === 409 &&
				signUpError.message ===
					`User with this email ${email} already exists.`
			) {
				setEmailError("User with this email address already exists.");
			}
		}
	}, [signUpError, email]);

	const validatePhone = useCallback(() => {
		const VALID_PHONE_NUMER_REGEX = /^\+\d{1,3}\d{10}$/;

		if (phone.trim() === "") {
			setPhoneError("Phone number is required.");
			return false;
		} else if (!VALID_PHONE_NUMER_REGEX.test(dialCode + phone)) {
			setPhoneError(
				"Invalid phone number (do not put spaces or dashes)."
			);
			return false;
		}

		setPhoneError("");
		return true;
	}, [phone, dialCode]);

	const validatePassword = useCallback(() => {
		if (password === "") {
			setPasswordError("Password is required.");
			return false;
		}
		if (password.trim() !== password) {
			setPasswordError("Password cannot begin or end with a space");
			return false;
		}
		if (password.length < 10) {
			setPasswordError("Password must be at least 10 characters long");
			return false;
		}
		if (!/[0-9]/.test(password)) {
			setPasswordError("Password must include at least one number");
			return false;
		}

		if (!/[A-Z]/.test(password)) {
			setPasswordError(
				"Password must include at least one uppercase letter"
			);
			return false;
		}
		if (!/[a-z]/.test(password)) {
			setPasswordError(
				"Password must include at least one lowercase letter"
			);
			return false;
		}

		const VALID_PASSWORD_REGEX =
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\^\$\*\.\[\]\{\}\(\)\?\"\!@#%&\/\\,><\':;|_~\`+= -])[A-Za-z\d\^\$\*\.\[\]\{\}\(\)\?\"\!@#%&\/\\,><\':;|_~\`+= -]{10,}$/;
		if (!VALID_PASSWORD_REGEX.test(password)) {
			setPasswordError(
				"Password must include at least one special symbol"
			);
			return false;
		}

		setPasswordError("");
		return true;
	}, [password]);

	const validateConfirmPassword = useCallback(() => {
		if (confirmPassword === "") {
			setConfirmPasswordError("Please confirm your password.");
			return false;
		}
		if (password !== confirmPassword) {
			setConfirmPasswordError("Passwords do not match.");
			return false;
		}
		setConfirmPasswordError("");
		return true;
	}, [confirmPassword, password]);

	const validateAgreeTermsAndPolicy = useCallback(() => {
		if (!agreeTermsAndPolicy) {
			setAgreeTermsAndPolicyError(
				"Please agree with Terms of service and Privacy Policy."
			);
			return false;
		}
		setAgreeTermsAndPolicyError("");
		return true;
	}, [agreeTermsAndPolicy]);

	const handleSignUp = useCallback(
		async (event) => {
			// Handle sign-up logic here
			event.preventDefault();

			const validations = [
				validateCompany,
				validateFirstName,
				validateLastName,
				validateEmail,
				validatePhone,
				validatePassword,
				validateConfirmPassword,
				validateAgreeTermsAndPolicy,
			];

			const allValid = validations
				.map((validation) => validation())
				.every((result) => result);

			if (allValid) {
				const signUpRequestBody = {
					email: email,
					password: password,
					confirm_password: confirmPassword,
					given_name: firstName,
					family_name: lastName,
					company_name: company,
					phone: dialCode + phone,
				};
				signUpUser(dispatch, signUpRequestBody);
			}
		},
		[
			dispatch,
			company,
			firstName,
			lastName,
			email,
			phone,
			dialCode,
			password,
			confirmPassword,
			validateCompany,
			validateFirstName,
			validateLastName,
			validateEmail,
			validatePassword,
			validateConfirmPassword,
			validatePhone,
			validateAgreeTermsAndPolicy,
		]
	);

	const navigateToSignIn = useCallback(() => {
		dispatch(resetState());
		navigate("/signin");
	}, [navigate, dispatch]);

	const signupOverrides = useMemo(() => {
		return {
			RhealizeLogo: {
				children: <AppLogo />,
				padding: "0px 0px 0px 0px",
			},

			SignUpFormSignupDefault: {
				height: "100vh",
				width: "50vw",
				overflow: "auto",
				padding: "5px 48px 24px 48px",
			},

			FirstName585734707: {
				value: company,
				onChange: handleCompanyChange,
				hasError: companyError ? true : false,
				errorMessage: companyError,
			},

			FirstName585734710: {
				value: firstName,
				onChange: handleFirstNameChange,
				hasError: firstNameError ? true : false,
				errorMessage: firstNameError,
			},

			LastName: {
				value: lastName,
				onChange: handleLastNameChange,
				hasError: lastNameError ? true : false,
				errorMessage: lastNameError,
			},

			Email: {
				value: email,
				onChange: handleEmailChange,
				hasError: emailError ? true : false,
				errorMessage: emailError,
			},

			PhoneNumberField: {
				value: phone,
				onChange: handlePhoneChange,
				placeholder: "Type your phone number (Eg. 6045551234)",
				hasError: phoneError ? true : false,
				errorMessage: phoneError,
				onDialCodeChange: handleDialCodeChange,
			},

			PasswordField: {
				label: "Password *",
				value: password,
				onChange: handlePasswordChange,
				hasError: passwordError ? true : false,
				errorMessage: passwordError,
			},

			ConfirmPasswordField: {
				label: "Confirm Password *",
				value: confirmPassword,
				onChange: handleConfirmPasswordChange,
				hasError: confirmPasswordError ? true : false,
				errorMessage: confirmPasswordError,
			},

			CheckboxField: {
				checked: agreeTermsAndPolicy,
				onChange: (event) => {
					if (!event.target.checked) {
						setAgreeTermsAndPolicy(false);
					} else {
						setTosAndPrivatePolicyVisible(true);
					}
				},
				hasError: agreeTermsAndPolicyError ? true : false,
				errorMessage: agreeTermsAndPolicyError,
			},

			Button: {
				onClick: handleSignUp,
				color: "white",
				backgroundColor: "rgb(34, 66, 114)",
			},

			"Already have an account? Login": {
				padding: "0px 5px",
				children: (
					<>
						Already have an account?{" "}
						<span
							onClick={navigateToSignIn}
							style={{
								fontWeight: "600",
								color: "rgba(49,91,155,1)",
								textDecoration: "none",
								cursor: "pointer",
							}}
						>
							Login
						</span>
					</>
				),
			},
		};
	}, [
		companyError,
		company,
		firstNameError,
		firstName,
		lastNameError,
		lastName,
		emailError,
		email,
		phoneError,
		phone,
		passwordError,
		password,
		confirmPasswordError,
		confirmPassword,
		agreeTermsAndPolicyError,
		agreeTermsAndPolicy,
		handleSignUp,
		navigateToSignIn,
	]);

	return (
		<div className="container">
			<div className="side-by-side">
				<StartHiring />
				<div className="form-container">
					{tosAndPrivatePolicyVisible && (
						<div className="tos-privacy-modal">
							<div className="modal-backdrop"></div>
							<Flex
								width="80%"
								height="70%"
								display="inline-flex"
								padding="10px 20px 20px 20px"
								direction="column"
								justifyContent="center"
								alignItems="flex-start"
								backgroundColor="rgba(255,255,255,1)"
								borderRadius="4px"
								gap="20px"
							>
								<Flex
									direction="row"
									width="100%"
									height="5%"
									justifyContent="space-between"
								>
									<Text fontSize="22px" fontWeight="600">
										Terms of Use and Privacy Policy
									</Text>
									<Close
										onClick={(event) => {
											setTosAndPrivatePolicyVisible(
												false
											);
										}}
										style={{
											cursor: "pointer",
										}}
									></Close>
								</Flex>
								{/* <PrivacyPolicyTermsUseCombined
                                    width="100%"
                                    height="80%"
                                    overflow="scroll"
                                    overrides={{
                                        Button: {
                                            variation: "link",
                                            children: "",
                                            isDisabled: true
                                        }
                                    }}
                                ></PrivacyPolicyTermsUseCombined> */}
								<Flex
									direction="column"
									width="100%"
									height="10%"
									justifyContent="center"
									alignItems="center"
									alignSelf="center"
								>
									<Button
										width="unset"
										height="unset"
										shrink="0"
										alignSelf="stretch"
										size="default"
										variation="primary"
										children="I agree"
										onClick={(event) => {
											setAgreeTermsAndPolicy(true);
											setTosAndPrivatePolicyVisible(
												false
											);
										}}
									></Button>
								</Flex>
							</Flex>
						</div>
					)}
					{signUpLoading && (
						<Loader
							width="50%"
							height="50%"
							transform="-50%, -100%"
						/>
					)}

					{signUpStatus === "" && (
						<SignUpFormSignupDefault
							overrides={signupOverrides}
						></SignUpFormSignupDefault>
					)}
					{signUpStatus === "Awaiting Verification" && (
						<Verification email={email}></Verification>
					)}

					{signUpStatus === "Verified" && (
						<Success
							email={email}
							password={password}
							firstName={firstName}
						/>
					)}
				</div>
			</div>
		</div>
	);
}

export default SignUp;
